import React, { Component } from 'react';
import { apiService } from '../services/ApiService';
import DOMPurify from 'dompurify';

export default class Mails extends Component {
  state = {
    email: '',
    messages: [],
    currentMessage: null,
    messageChosen: false,
  };

  constructor(props) {
    super(props);
  }

  async openMessage(messageId) {
    const currentMessage = await apiService.getMessageByPk(messageId);
    this.setState(() => {
      return { currentMessage: currentMessage };
    });
    this.setState(() => {
      return { messageChosen: true };
    });
  }

  async componentDidMount() {
    let email = window.location.href.substring(
      window.location.href.lastIndexOf('/') + 1
    );
    this.setState(() => {
      return { email: email };
    });
    const account = await apiService.getAccountByPk(email);
    if (account) {
      const messages = await apiService.getAllMessagesFromEmail(email);
      this.setState(() => {
        return { messages: messages };
      });
    }
  }

  render() {
    return (
      <div className="app">
        <div className="header">
          <h3>
            Emails for <i>{this.state.email}</i>
          </h3>
        </div>
        <div className="mailReader">
          <div className="messageBox">
            {this.state.messages.map((msg, i) => (
              <div
                className="message"
                key={msg.id + i}
                onClick={() => this.openMessage(msg.id)}
              >
                <div className="date">{msg.timeSent}</div>
                <div className="from">{msg.from}</div>
                <div className="subject">{msg.title}</div>
              </div>
            ))}
          </div>
          <div className="messageReader">
            {this.state.messageChosen ? (
              <div
                className="content"
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(this.state.currentMessage.text),
                }}
              ></div>
            ) : this.state.currentMessage ? (
              <div
                className="content"
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(this.state.currentMessage.text),
                }}
              ></div>
            ) : null}
          </div>
        </div>
      </div>
    );
  }
}
