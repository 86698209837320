import makeApolloClient from './utils/makeApolloClient';
import gql from 'graphql-tag';

const GET_ACCOUNTS = gql`
  query Accounts {
    accounts {
      _id
      group
      email
      password
      appPassword
      imapActivated
      microsoftUserPuid
      phoneNumber
      phoneRegion
      GAuth
      recoveryCodes
      status
      statusDescription
      httpProxy
      socksProxy
      lastFetch
      dateOfBirthday
      firstName
      lastName
      xboxGamerTag
      proxyRotationURL
      idAliasEmail0
      idAliasEmail1
    }
  }
`;

const GET_ACCOUNT_BY_PK = gql`
  query GetAccountByPk($email: String!) {
    accounts_by_pk(email: $email) {
      _id
      group
      email
      password
      appPassword
      imapActivated
      microsoftUserPuid
      phoneNumber
      phoneRegion
      GAuth
      recoveryCodes
      status
      statusDescription
      httpProxy
      socksProxy
      lastFetch
      dateOfBirthday
      firstName
      lastName
      xboxGamerTag
      proxyRotationURL
      idAliasEmail0
      idAliasEmail1
    }
  }
`;

const GET_ALL_MESSAGES_FROM_EMAIL = gql`
  query GetAllMessagesFromEmail($email: String) {
    messages(where: { accountEmail: { _eq: $email } }) {
      id
      accountEmail
      text
      timeSent
      title
      from
    }
  }
`;

const GET_MESSAGE_BY_PK = gql`
  query GetMessageByPk($id: uuid!) {
    messages_by_pk(id: $id) {
      id
      text
    }
  }
`;

const CREATE_ACCOUNTS = gql`
  # mutation CreateAccounts($objects: [accounts_insert_input!]!) {
  #   insert_accounts(objects: $objects) {
  #     affected_rows
  #   }
  # }
  mutation CreateAccounts($objects: [accounts_insert_input!]!) {
    insert_accounts(
      objects: $objects
      on_conflict: { constraint: accounts_email_key, update_columns: [status] }
    ) {
      affected_rows
    }
  }
`;

const SUBSCRIBE_ACCOUNTS = gql`
  subscription MySubscription {
    accounts {
      _id
      group
      email
      password
      appPassword
      imapActivated
      microsoftUserPuid
      phoneNumber
      phoneRegion
      GAuth
      recoveryCodes
      status
      statusDescription
      httpProxy
      socksProxy
      lastFetch
      dateOfBirthday
      firstName
      lastName
      xboxGamerTag
      proxyRotationURL
      idAliasEmail0
      idAliasEmail1
    }
  }
`;

const DELETE_ACCOUNT = gql`
  mutation DeleteAccountByPk($email: String!) {
    delete_accounts_by_pk(email: $email) {
      email
    }
  }
`;

const ARCHIVE_ACCOUNT = gql`
  mutation UpdateAccountsStatuses($emails: [String!]!) {
    update_accounts_many(
      updates: {
        where: { email: { _in: $emails } }
        _set: { status: "ARCHIVED" }
      }
    ) {
      affected_rows
    }
  }
`;

const UPDATE_ACCOUNT = gql`
  mutation UpdateAccountById($id: uuid!, $_set: accounts_set_input = {}) {
    update_accounts(where: {_id: {_eq: $id}}, _set: $_set) {
      affected_rows
    }
  }
`;

const UPDATE_BULK_ACCOUNTS = gql`
  mutation UpdateAccountById($updates: [accounts_updates!]!) {
    update_accounts_many(updates: $updates) {
      affected_rows
    }
  }
`;

const UPDATE_ACCOUNTS_STATUSES = gql`
  mutation UpdateAccountsStatuses($emails: [String!]!, $description: String) {
    update_accounts_many(
      updates: {
        where: { email: { _in: $emails } }
        _set: { status: "ABANDONED", statusDescription: $description }
      }
    ) {
      affected_rows
    }
  }
`;

const UPDATE_ACCOUNT_STATUS_BY_PK = gql`
  mutation UpdateAccountStatusByPk($email: String!, $status: String) {
    update_accounts_by_pk(
      pk_columns: { email: $email }
      _set: { status: $status }
    ) {
      email
    }
  }
`;

class ApiService {
  client;

  constructor(client) {
    this.client = client;
  }

  getAccounts = async () => {
    try {
      const result = await this.client.query({
        query: GET_ACCOUNTS,
      });
      return result.data.accounts;
    } catch (err) {
      console.log('ERROR getAccounts:', err);
    }
  };

  getAccountByPk = async (email) => {
    try {
      const result = await this.client.query({
        query: GET_ACCOUNT_BY_PK,
        variables: {
          email,
        },
      });
      return result.data.accounts_by_pk;
    } catch (err) {
      console.log('ERROR getAccountByPk:', err);
    }
  };

  getAllMessagesFromEmail = async (email) => {
    try {
      const result = await this.client.query({
        query: GET_ALL_MESSAGES_FROM_EMAIL,
        variables: {
          email,
        },
      });
      return result.data.messages;
    } catch (err) {
      console.log('ERROR getAllMessagesFromEmail:', err);
    }
  };

  getMessageByPk = async (id) => {
    try {
      const result = await this.client.query({
        query: GET_MESSAGE_BY_PK,
        variables: {
          id,
        },
      });
      return result.data.messages_by_pk;
    } catch (err) {
      console.log('ERROR getMessageByPk:', err);
    }
  };

  createAccounts = async (data) => {
    try {
      const result = await this.client.mutate({
        mutation: CREATE_ACCOUNTS,
        variables: {
          objects: data,
        },
      });
      console.log(result);
      return result.data.insert_accounts;
    } catch (err) {
      console.log('ERROR createAccounts:', err);
    }
  };

  deleteAccount = async (email) => {
    try {
      const result = await this.client.mutate({
        mutation: DELETE_ACCOUNT,
        variables: {
          email,
        },
      });
      console.log(result);
      return result.data.Account;
    } catch (err) {
      console.log('ERROR deleteAccount:', err);
    }
  };

  archiveAccount = async (emails) => {
    try {
      const result = await this.client.mutate({
        mutation: ARCHIVE_ACCOUNT,
        variables: {
          emails,
        },
      });
      console.log(result);
    } catch (err) {
      console.log('ERROR archiveAccount:', err);
    }
  };

  updateAccount = async (data) => {
    try {
      const result = await this.client.mutate({
        mutation: UPDATE_ACCOUNT,
        variables: {
          id: data._id,
          _set: data,
        },
      });
      console.log(result);
    } catch (err) {
      console.log('ERROR updateAccount:', err);
    }
  };

  updateBulkAccs = async (data) => {
    try {
      const result = await this.client.mutate({
        mutation: UPDATE_BULK_ACCOUNTS,
        variables: {
          updates: data
        },
      });
      console.log(result);
    } catch (err) {
      console.log('ERROR updateBulkAccs:', err);
    }
  };

  updateAccountsStatus = async (emails, description) => {
    try {
      const result = await this.client.mutate({
        mutation: UPDATE_ACCOUNTS_STATUSES,
        variables: {
          emails,
          description,
        },
      });
      console.log(result);
    } catch (err) {
      console.log('ERROR updateAccountsStatus:', err);
    }
  };

  updateAccountStatusByPk = async (data) => {
    try {
      const result = await this.client.mutate({
        mutation: UPDATE_ACCOUNT_STATUS_BY_PK,
        variables: {
          email: data.email,
          status: data.status,
        },
      });
      console.log(result);
    } catch (err) {
      console.log('ERROR updateAccountByPk:', err);
    }
  };
}

const client = makeApolloClient(
  process.env.REACT_APP_API_URL,
  process.env.REACT_APP_API_WS_URL
);
const apiService = new ApiService(client);
export { client, apiService, SUBSCRIBE_ACCOUNTS };
