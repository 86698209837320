const emails = require('email-generator');
const generator = require('generate-password-browser');
import { ALL_ENG_NAMES } from './usernames';
import { gpt_passwords } from './gpt_passwords';

const randomInt = (from, to) => {
  if (to < from) {
    throw new Error(`Wrong range: from ${from} to ${to}`);
  }
  const d = (to - from) * Math.random();
  return from + Math.ceil(d);
};

const shuffle = (array) => {
  let currentIndex = array.length;
  let randomIndex;

  while (currentIndex != 0) {
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;

    [array[currentIndex], array[randomIndex]] = [
      array[randomIndex],
      array[currentIndex],
    ];
  }

  return array;
};

const genTwoWords = (lowercase = false) => {
  const size = ALL_ENG_NAMES.length - 1;
  const idx_first = randomInt(0, size);
  const idx_last = randomInt(0, size);
  let first_word = ALL_ENG_NAMES[idx_first].slice(1);
  let last_word = ALL_ENG_NAMES[idx_last].slice(1);

  if (lowercase) {
    first_word = first_word.toLowerCase();
    last_word = last_word.toLowerCase();
  }

  return { first_word, last_word };
};

function randomSeparator() {
  const rand = randomInt(0, 3);

  if (rand === 1) return '_';

  if (rand === 2) return '';

  return '-';
}

export function genEmail() {
  let generatedEmail = emails.generateEmail();

  const [tmpEmailName] = generatedEmail.split('@');

  const emailName = tmpEmailName.replace('"', '').replace('.', randomSeparator());
  const email = `${emailName}@outlook.com`;
  return email;
}

export function genPass() {
  const randomPass = gpt_passwords[Math.floor(Math.random() * gpt_passwords.length)];
  return randomPass;
}
