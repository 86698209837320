const columnDefsAccounts = [
  {
    field: 'group',
    headerName: 'Group',
    editable: true,
    enableRowGroup: true,
  },
  {
    field: 'email',
    headerName: 'Email',
    editable: true,
    enableRowGroup: true,
    checkboxSelection: true,
    valueGetter: (params) => {
      return params.data.email;
    },
    // valueSetter: (params) => {
    //   var newVal = params.newValue;
    //   var valueChanged = params.data.email !== newVal;
    //   if (valueChanged) {
    //     params.data.email = newVal;
    //   }
    //   return valueChanged;
    // },
  },
  {
    field: 'status',
    headerName: 'Status',
    editable: true,
    enableRowGroup: true,
    valueGetter: (params) => {
      return params.data.status;
    },
    valueSetter: (params) => {
      var newVal = params.newValue;
      var valueChanged = params.data.status !== newVal;
      if (valueChanged) {
        params.data.status = newVal;
      }
      return valueChanged;
    },
  },
  {
    field: 'statusDescription',
    headerName: 'Status description',
    editable: true,
    enableRowGroup: true,
  },
  {
    field: 'microsoftUserPuid',
    headerName: 'Microsoft user Puid',
    editable: true,
    enableRowGroup: true,
  },
  {
    field: 'phoneRegion',
    headerName: 'Phone region',
    editable: true,
    enableRowGroup: true,
  },
  {
    field: 'xboxGamerTag',
    headerName: 'Gamer tag',
    editable: true,
    enableRowGroup: true,
  },
  {
    field: 'httpProxy',
    headerName: 'Http proxy',
    editable: true,
    enableRowGroup: true,
  },
  {
    field: 'socksProxy',
    headerName: 'Socks proxy',
    editable: true,
    enableRowGroup: true,
  },
  {
    field: 'proxyRotationURL',
    headerName: 'Proxy rotation',
    editable: true,
    enableRowGroup: true,
  },
  {
    field: 'password',
    headerName: 'Password',
    editable: true,
    enableRowGroup: true,
  },
  {
    field: 'appPassword',
    headerName: 'App password',
    editable: true,
    enableRowGroup: true,
  },
  {
    field: 'imapActivated',
    headerName: 'Imap activated',
    editable: true,
    enableRowGroup: true,
  },
  {
    field: 'phoneNumber',
    headerName: 'Proof phone number',
    editable: true,
    enableRowGroup: true,
  },
  {
    field: 'GAuth',
    headerName: 'GAUTH',
    editable: true,
    enableRowGroup: true,
  },
  {
    field: 'recoveryCodes',
    headerName: 'Recovery',
    editable: true,
    enableRowGroup: true,
  },
  {
    field: 'dateOfBirthday',
    headerName: 'Birthday',
    editable: true,
    enableRowGroup: true,
  },
  {
    field: 'firstName',
    headerName: 'First name',
    editable: true,
    enableRowGroup: true,
  },
  {
    field: 'lastName',
    headerName: 'Last name',
    editable: true,
    enableRowGroup: true,
  },
  {
    field: 'idAliasEmail0',
    headerName: 'Alias 0',
    editable: true,
    enableRowGroup: true,
  },
  {
    field: 'idAliasEmail1',
    headerName: 'Alias 1',
    editable: true,
    enableRowGroup: true,
  },
];

export default columnDefsAccounts;
